import React from 'react';
import { Container, Row, Col, Pagination, Dropdown } from 'react-bootstrap';
import { LanguageProvider } from '../../../core/language-provider';


export default function PaginationBlock({ page, totalPages, limit, setLimit, changePage }) {

    function goToPrevPage() {
        if (page > 1) {
            changePage(page - 1)
        }
    }
    function goToNextPage() {
        if (page < totalPages) {
            changePage(page + 1)
        }
    }
    function goToFirstPage() {
        if (page !== 1) {
            changePage(1)
        }
    }
    function goToLastPage() {
        if (page !== totalPages) {
            changePage(totalPages)
        }
    }


    return (
        <Container className="mt-4" fluid>
            <Row>
                <Col sm={6}>
                    <Pagination>
                        <Pagination.First className={page <= 1 ? 'disabled' : ''} onClick={goToFirstPage} />
                        <Pagination.Prev className={page <= 1 ? 'disabled' : ''} onClick={goToPrevPage} />
                        <Pagination.Item key={page}>
                            {page} <span className='text-muted small'>... {totalPages}</span>
                        </Pagination.Item>
                        <Pagination.Next className={page >= totalPages ? 'disabled' : ''} onClick={goToNextPage} />
                        <Pagination.Last className={page >= totalPages ? 'disabled' : ''} onClick={goToLastPage} />
                    </Pagination>
                </Col>
                <Col sm={6} className="text-sm-end">
                    <Dropdown>
                        <Dropdown.Toggle variant="outline-secondary" id="dropdown-resultPerPage">
                            {LanguageProvider.get('defaults.results_per_page')}: {limit}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item className="small" onClick={() => setLimit(10)}>10</Dropdown.Item>
                            <Dropdown.Item className="small" onClick={() => setLimit(20)}>20</Dropdown.Item>
                            <Dropdown.Item className="small" onClick={() => setLimit(50)}>50</Dropdown.Item>
                            <Dropdown.Item className="small" onClick={() => setLimit(100)}>100</Dropdown.Item>
                            <Dropdown.Item className="small" onClick={() => setLimit(200)}>200</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </Col>
            </Row>
        </Container>
    );
};